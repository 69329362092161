/* IBMPlexSans-Light - 300 */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
        url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-Light.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-Light.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* IBMPlexSans-LightItalic - 300 */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
        url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-LightItalic.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-LightItalic.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* IBMPlexSans-Regular - 400 */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local('IBM Plex Sans Regular'), local('IBMPlexSans-Regular'),
        url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-Regular.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-Regular.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* IBMPlexSans-Italic - 400 */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
        url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-Italic.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-Italic.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* IBMPlexSans-SemiBold - 600 */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
        url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-SemiBold.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-SemiBold.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* IBMPlexSans-SemiBoldItalic - 600 */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local('IBM Plex Sans SemiBold Italic'), local('IBMPlexSans-SemiBoldItalic'),
        url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-SemiBoldItalic.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/IBMPlexSans-SemiBoldItalic.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* TiemposHeadline-Black */
@font-face {
    font-family: 'Tiempos Headline';
    font-style: normal;
    font-weight: 900;
    src: local('Tiempos Headline Black'), local('TiemposHeadline-Black'),
        url('https://s3.eu-central-1.amazonaws.com/uberall-static-prod/assets/TiemposHeadline-Black.otf')
            format('opentype'); /* Chrome 4+, Firefox 3.5+, Safari 3.1+, IE11 (partial support) */
}
